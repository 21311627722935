.radix-themes {
    --cursor-button: pointer;
    --cursor-checkbox: pointer;
    --cursor-disabled: not-allowed;
    --cursor-link: pointer;
    --cursor-menu-item: pointer;
    --cursor-radio: pointer;
    --cursor-slider-thumb: pointer;
    --cursor-slider-thumb-active: pointer;
    --cursor-switch: pointer;
  }