/* Copied from https://tailwindcss.com/docs/preflight */

* {
  font-family: 'Inter', sans-serif;
  font-feature-settings: 'ss03' on, 'ss04' on, 'liga' 1, 'calt' 1, 'dlig' on;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

@supports (font-variation-settings: normal) {
  * {
    font-family: 'InterVariable', sans-serif;
  }
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  /* vertical-align: middle; */
}

img,
video {
  max-width: 100%;
  height: auto;
}

button {
  background: none;
  padding: 0;
}

a {
  text-decoration: inherit;
}

p {
  white-space: pre-wrap;
}

*,
::before,
::after {
  border-width: 0;
  border-style: solid;
}

body {
  display: block;
}

textarea {
  background: transparent;
}

.font-general-sans {
  font-family: 'General Sans Variable', sans-serif;
}

.markdown-content {
  font-size: 14px;
  line-height: 1.6;
  font-weight: normal;
  color: var(--mauve-11);
}

.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content h5,
.markdown-content h6 {
  font-size: 1em;
  font-weight: normal;
}

.markdown-content p,
.markdown-content ul,
.markdown-content ol,
.markdown-content li {
  font-weight: normal;
  margin-top: 5px;
  margin-bottom: 5px;
}

.markdown-content a {
  font-weight: normal;
  color: #0645ad;
  text-decoration: none;
}

.markdown-content a:hover {
  font-weight: normal;
  text-decoration: underline;
}

.markdown-content blockquote {
  font-weight: normal;
  padding: 10px 20px;
  color: #464262;
  border-left: 5px solid #ccc;
}

.markdown-content code {
  font-weight: normal;
  background-color: #f6f8fa;
  padding: 3px 5px;
  border-radius: 5px;
}

.markdown-content pre {
  font-weight: normal;
  background-color: #f6f8fa;
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 5px;
  overflow-x: auto;
}

.markdown-content img {
  max-width: 100%;
  height: auto;
}

.markdown-content table {
  font-weight: normal;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 15px;
}

.markdown-content table th,
.markdown-content table td {
  font-weight: normal;
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

.markdown-content strong,
.markdown-content b {
  font-weight: normal;
}